<template>
  <div v-if="detail">
    <ConfigProvider :theme-vars="themeVars">
      <div class="head-wrap"></div>
      <div class="title-wrap">
        <div class="title">{{ detail.title }}</div>
      </div>
      <div class="tab-wrap">
        <Tabs>
          <Tab title="基本信息">
            <div class="label-wrap">
              <div class="label">概述</div>
              <div class="value">{{ detail.abstract }}</div>
            </div>
            <div class="label-wrap" v-if="detail.publish_year">
              <div class="label">发表时间</div>
              <div class="value">{{ detail.publish_year }}</div>
            </div>
            <div class="label-wrap" v-if="detail.institute">
              <div class="label">发布机构</div>
              <div class="value">{{ detail.institute }}</div>
            </div>
            <div class="label-wrap" v-if="detail.journal">
              <div class="label">期刊名称</div>
              <div class="value">{{ detail.journal }}</div>
            </div>
            <div class="label-wrap" v-if="detail.impact_factor">
              <div class="label">影响因子</div>
              <div class="value">{{ detail.impact_factor }}</div>
            </div>
            <div class="label-wrap" v-if="detail.doi">
              <div class="label">DOI</div>
              <div class="value">{{ detail.doi }}</div>
            </div>
            <div class="label-wrap" v-if="detail.authors">
              <div class="label">通讯作者</div>
              <div class="value">{{ detail.authors }}</div>
            </div>
            <div class="label-wrap">
              <div class="label">样本量</div>
              <div class="value">{{ detail.sample_size }}</div>
            </div>
            <!-- <div class="label-wrap">
              <div class="label">通讯作者单位</div>
              <div class="value">{{ detail.authors }}</div>
            </div> -->
          </Tab>
          <Tab title="摘要">
            <div class="label-wrap">
              <div class="label">研究目的</div>
              <div class="value">{{ detail.research_purpose }}</div>
            </div>
            <div class="label-wrap">
              <div class="label">研究方法</div>
              <div class="value">{{ detail.research_method }}</div>
            </div>
            <div class="label-wrap">
              <div class="label">研究结果</div>
              <div class="value">{{ detail.research_result }}</div>
            </div>
            <div class="label-wrap">
              <div class="label">研究结论</div>
              <div class="value">{{ detail.research_conclusion }}</div>
            </div>
          </Tab>
          <Tab title="要点">
            <div class="label-wrap" v-for="(item, index) in detail.key_points" :key="index">
              <div class="label">
                <div>要点{{ index + 1 }}</div>
                <div class="tag">{{ item.data_type }}</div>
              </div>
              <div class="value">{{ item.content }}</div>
            </div>
          </Tab>
          <Tab title="图片">
            <div class="image-wrap">
              <div class="image-item" v-for="(item, index) in detail.images" :key="index" @click="handleImgClick(item)">
                <img :src="item.url" alt="" />
                <div class="name">图：{{ item.name }}</div>
                <div v-if="item.comment" class="des">注释：{{ item.comment }}</div>
              </div>
              <Empty description="暂无数据" v-if="detail.images.length === 0" />
            </div>
          </Tab>
          <Tab title="相关文献">
            <div class="releated-wrap">
              <div
                class="article-item"
                v-for="(item, index) in detail.related_articles"
                :key="item.id"
                @click="handleLiteratureClick(item)"
              >
                {{ index + 1 }}、{{ item.title }}
              </div>
              <Empty description="暂无数据" v-if="detail.related_articles.length === 0" />
            </div>
          </Tab>
        </Tabs>
      </div>
    </ConfigProvider>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Tab, Tabs, ConfigProvider, Empty, ImagePreview } from 'vant'
import { getZhikuDetail } from '@/api/zhiku'

const themeVars = {
  tabActiveTextColor: '#0C86FE',
  tabsBottomBarColor: '#0C86FE',
  tabsBottomBarHeight: '2px'
}
const router = useRouter()
const route = useRoute()
const detail = ref(null)

const getDetail = async () => {
  const result = await getZhikuDetail(route.query.id)
  console.log(result)
  if (result.code === 200) {
    detail.value = {
      ...result.data,
      authors: result.data.authors && result.data.authors.map(item => item.name).filter(item => item).join(',')
    }
  }
}

getDetail()

const handleImgClick = item => {
  if (item.url) {
    ImagePreview([item.url])
  }
}

watch(() => route.query.id, () => {
  getDetail()
})

const handleLiteratureClick = item => {
  console.log(item)
  if (item.id) {
    router.replace(`/zhiku/detail/literature?id=${item.id}`)
  }
}
</script>

<style lang="less" scoped>
.head-wrap {
  height: 88px;
  background: url('../../../assets/detail.png');
  background-size: contain;
}
.title-wrap {
  margin-top: -44px;
  padding: 0 16px;

  .title {
    background: #ffffff;
    border-radius: 4px;
    padding: 16px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 18px;
  }
}

.tab-wrap {
  margin-top: 12px;
}

.label-wrap {
  padding: 0 20px 20px 20px;
  background-color: #ffffff;

  &:nth-of-type(1) {
    padding-top: 20px;
  }
  .label {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 16px;
    padding-left: 12px;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 14px;
      background: #0c86fe;
      border-radius: 2px;
    }

    .tag {
      width: 36px;
      height: 16px;
      margin-left: 12px;
      background: linear-gradient(135deg, #ff9b44 0%, #ff5824 100%);
      border-radius: 4px;
      color: #fff;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
    }
  }
  .value {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
    margin-top: 8px;
  }
}

.image-wrap {
  background-color: #fff;
  padding: 20px;

  .image-item {
    .name {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 16px;
      text-align: center;
    }

    .des {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
      text-align: center;
      margin-top: 8px;
    }

    & > img {
      width: 100%;
      height: 160px;
      object-fit: contain;
      margin-top: 12px;
    }
  }
}

.releated-wrap {
  padding: 20px;
  background-color: #fff;

  .article-item {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #0c86fe;
    line-height: 20px;
    margin-bottom: 24px;
  }
}
</style>
